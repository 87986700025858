<template>
    <div>
        <!-- <vx-tooltip class="position-icon" :text="$t('button.detail')" position="top">
            <feather-icon icon="CameraIcon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer"
                          class="action-warn" @click="detailService"/>
        </vx-tooltip> -->
        <vx-tooltip class="position-icon" :text="$t('button.edit')" position="top">
            <feather-icon icon="EditIcon" svgClasses="h-5 w-5 hover:text-primary cursor-pointer"
                          class="action-success" @click="editService"/>
        </vx-tooltip>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        editService() {
            this.$router.push(`/admin/edit-serviceAI?id=${this.params.data.id}`).catch(() => {
            })
        },
        // detailService() {
        //     this.$vs.loading();
        //     this.$crm.post(`/camera/admin/delete/${this.params.data.organizationId}/${this.params.data.id}`).then(() => {
        //         this.$vs.loading.close();
        //         this.$store.commit('DELETE_CAMERA_STATUS', true);
        //         return this.showDeleteSuccess();
        //     }).catch((err) => {
        //         this.$vs.loading.close();
        //         return this.$vs.notify({
        //             text: err.response.data.message || err.response.data.error_description || err.response.data.error,
        //             iconPack: 'feather',
        //             icon: 'icon-alert-circle',
        //             color: 'danger'
        //         });
        //     });
        // },
    }
}
</script>
