<template>
    <vs-chip class="ag-grid-cell-chip" :color="chipColor(params.data.status)">
        <span>{{ statusTypeString(params.data.status) }}</span>
    </vs-chip>
</template>

<script>
export default {
    name: 'CellRenderServiceStatus',
    computed: {
        chipColor() {
            return (value) => {
                switch (value) {
                    case '0':
                        return "danger";
                    case '1':
                        return "success";
                }
            }
        },
        statusTypeString() {
            return (value) => {
                switch (value) {
                    case '0':
                        return this.$t('root.stopWorking');
                    case '1':
                        return this.$t('root.active');
                    default :
                    return ''
                }
            }
        }
    },
}
</script>

<style lang="scss" scpoped>
.ag-grid-cell-chip {
    &.vs-chip-success {
        background: rgba(var(--vs-success),.15);
        color: rgba(var(--vs-success),1) !important;
        font-weight: 500;
    }
    &.vs-chip-warning {
        background: rgba(var(--vs-warning),.15);
        color: rgba(var(--vs-warning),1) !important;
        font-weight: 500;
    }
    &.vs-chip-danger {
        background: rgba(var(--vs-danger),.15);
        color: rgba(var(--vs-danger),1) !important;
        font-weight: 500;
    }
}
</style>
