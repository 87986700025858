<template>
    <div id="page-camera-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-center">
                <div class="flex-grow">
                    <div class="vx-col md:w-1/4 sm:w-auto mr-4">
                        <v-select class="" v-model="aiServiceModel" :clearable="false" :options="aiServiceOptions">
                            <span slot="no-options">{{ $t('menuOrganization.emptyList') }}</span>
                        </v-select>
                    </div>
                </div>
                <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4"
                    v-model="searchQuery" @input="updateSearchQuery" :placeholder="$t('button.search')" />
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                :gridOptions="gridOptions"
                class="ag-theme-material w-100 my-4 ag-grid-table"
                :columnDefs="columnDefs"
                :defaultColDef="defaultColDef"
                :rowData="aiServiceData"
                :animateRows="true"
                :pagination="true"
                :localeText="{ noRowsToShow: this.$t('root.noRowsToShow') }"
                :paginationPageSize="20"
                :onGridReady="onGridReady"
                :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination :total="totalPages" :max="maxPageNumbers" v-model="currentPage" />
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select';
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRenderServiceActions from "./cell-renderer/CellRenderServiceActions"
import CellRenderServiceStatus from "./cell-renderer/CellRenderServiceStatus"
import _enum from '../../../enum'

export default {
    components: {

        AgGridVue,
        vSelect,
        // Cell Renderer
        CellRenderServiceActions,
        CellRenderServiceStatus,
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 80
                },
                {
                    headerName: this.$t('root.AIServiceName'),
                    field: 'name',
                    width: 150,
                },
                {
                    headerName: this.$t('root.AIServiceIP'),
                    field: 'ip',
                    width: 150
                },
                {
                    headerName: this.$t('root.apiPort'),
                    field: 'apiPort',
                    width: 80
                },
                {
                    headerName: this.$t('root.domain'),
                    field: 'domain',
                    width: 250
                },
                {
                    headerName: this.$t('root.maximumCamera'),
                    field: 'maximumCamera',
                    width: 160
                },
                {
                    headerName: this.$t('root.AIServiceStatus'),
                    field: 'status',
                    width: 180,
                    cellRendererFramework: 'CellRenderServiceStatus'
                },
                {
                    headerName: this.$t('button.actions'),
                    field: 'actions',
                    width: 120,
                    cellRendererFramework: 'CellRenderServiceActions',
                },
            ],
            aiServiceModelValue: null,
            aiServiceOptions: [],
            aiServiceData: [],
        }
    },
    created() {
        this.initScreen()
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
    },
    computed: {
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        },
        aiServiceModel: {
            get() {
                return {
                    label: this.aiServiceString(this.aiServiceModelValue),
                    value: this.aiServiceModelValue
                }
            },
            set(obj) {
                this.aiServiceModelValue = obj.value
            }
        }
    },
    methods: {
        aiServiceString(id) {
            let aiService = this.aiServiceOptions.find(x => x.value === id);
            return (aiService && aiService.label) || 'Tất cả';
        },
        updateSearchQuery(val) {
            this.gridApi.setQuickFilter(val)
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        async initScreen() {
            await this.loadAiService()
        },
        loadAiService() {
            return new Promise((resolve) => {
                this.$vs.loading();
                this.$crm.get("/ai-service/find-all").then((response) => {
                    this.$vs.loading.close();
                    this.aiServiceData = response.data
                    this.aiServiceOptions = response.data.map(item => {
                        return {
                            value: item.id,
                            label: item.name
                        }
                    })
                    resolve(response.data);
                })
            })
        }
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    }
}
</script>

<style scoped>
</style>
